import React from "react"

import close from "../../assets/icons/close.svg"

interface Props {
  name?: String,
  handleRemove?: () => void,
  className?: String,
  isRemoveable?: Boolean,
}

const SelectedOption = ({name, handleRemove, className, isRemoveable = true}: Props) => {
  return (
    <span className={`flex border border-gray-200 rounded-5 text-gray-800 font-base-light ${className}`}>
      {name}
      {isRemoveable && (
        <img src={close} onClick={handleRemove} className='w-7 ml-5 cursor-pointer'/>
      )}
    </span>
  )
}

export default SelectedOption