import React from 'react';
import ResellerForm from '../components/Reseller/ResellerForm';
import ActivationCodes from '../components/ActivationCodes';

class PageHandler extends React.Component<Props> {
  render() {
    const pageType = this.props.match.params.page;

    switch (pageType) {
      case 'reseller':
        return <ResellerForm />;
      default: // "activation-codes":
        return <ActivationCodes />;
    }
  }
}

export default PageHandler;
