import React, { useState } from 'react';
import Button from '../Button';
import AddReseller from './AddReseller';

import EditReseller from './EditReseller';

const ResellerForm = () => {
  const [isEditMode, setMode] = useState(false);

  return (
    <div className="w-80/100">
      <div className="flex justify-end mb-20">
        <Button
          classNames=" btn btn-primary w-btn-xs mr-20"
          type="submit"
          handleOnClick={() => {
            setMode(false);
          }}
          disabled={!isEditMode}
        >
          Add
        </Button>
        <Button
          classNames=" btn btn-primary w-btn-xs"
          type="submit"
          handleOnClick={() => {
            setMode(true);
          }}
          disabled={isEditMode}
        >
          Edit
        </Button>
      </div>
      {isEditMode ? <EditReseller /> : <AddReseller />}
    </div>
  );
};

export default ResellerForm;
