import React from 'react';
import Sprinner from './Spinner';

import '../../styles/indicator.css';

interface LoadingIndicatorProps {
  className?: string;
  children?: React.ReacNode;
}

const LoadingIndicator = ({ children, className = '' }: LoadingIndicatorProps) => (
  <div
    className={`w-full h-full absolute flex top-0 left-0 z-50 justify-center items-center text-white loading-indicator bg-white"
    } ${className}`}
  >
    {children ? children : <Sprinner />}
  </div>
);

export default LoadingIndicator;
