import React, { useState, createContext, useEffect } from 'react';
import Textbox from '../components/Textbox';
import FormList from '../components/FormList';
import search from '../assets/icons/search.svg';
import { Route, Switch } from 'react-router-dom';
import Button from '../components/Button';
import Amplify, { Auth } from 'aws-amplify';
import { cognitoConfig } from '../utils/utilities';
import { useHistory } from 'react-router-dom';
import { clearLocalStorage, checkIsAdmin } from '../utils/utilities';
import ResellerForm from '../components/Reseller/ResellerForm';
import PageHandler from './PageHandler';
import ActivationCodes from '../components/ActivationCodes';

const formList = [
  {
    id: 'activation-codes',
    title: 'Activation Codes',
    component: <ActivationCodes />,
    path: '/admin/activation-codes',
  },
  {
    id: 'reseller',
    title: 'Reseller',
    component: <ResellerForm />,
    path: '/admin/reseller',
  },
];

export const GlobalContext = createContext({});

Amplify.configure(cognitoConfig);

const Dashboard = () => {
  const [selectedForm, setSelectedForm] = useState<Form | undefined | null>(formList[0]);
  const [currentList, setCurrentList] = useState(formList);
  const [unauthorized, setUnauthorized] = useState(false);
  const [error, setError] = useState('');
  let history = useHistory();

  const handleSearch = (key) => {
    const list = formList.filter((item) => item.title.toLowerCase().includes(key.toLowerCase()));

    setCurrentList(list);

    setSelectedForm(list[0]);
  };

  useEffect(() => {
    Auth.currentSession()
      .then((user) => {
        setUnauthorized(!checkIsAdmin(user));
      })
      .catch(() => {
        history.push('/login');
      });
  }, [history]);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      history.push('/login');
      clearLocalStorage();
    } catch (err) {
      setError(err);
    }
  };

  const renderApp = () => (
    <>
      {error && <p className="text-error">{error}</p>}
      <div className="flex justify-end mx-auto max-w-1160 mt-80 mb-50">
        <Button
          classNames="text-xl font-base-light text-gray-800 underline"
          handleOnClick={handleSignOut}
        >
          Sign out
        </Button>
      </div>
      <div className="flex mx-auto max-w-1160">
        {!unauthorized ? (
          <>
            <div className="w-300 border-r border-gray-80 mr-30">
              <div className="relative">
                <img className="absolute w-icon-lg left-5 top-11 z-100" src={search} alt="search" />
                <Textbox
                  classNames={{
                    wrapper: 'w-260 mb-32',
                    textbox: 'h-textbox-lg truncate w-260 pl-30',
                  }}
                  handleOnChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                />
              </div>
              <FormList
                list={currentList}
                handleSelectForm={(form: Form) => {
                  setSelectedForm(form);
                }}
                selectedForm={selectedForm}
              />
            </div>
            <div className="w-70/100">
              <Switch>
                <Route exact path="/admin/:page?" component={PageHandler} />
              </Switch>
            </div>
          </>
        ) : (
          <p className="font-base-black text-center text-xxl max-w-400 mx-auto">401 Unauthorized</p>
        )}
      </div>
    </>
  );

  return <GlobalContext.Provider value={[setUnauthorized]}>{renderApp()}</GlobalContext.Provider>;
};

export default Dashboard;
