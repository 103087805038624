import React from 'react';
import { useHistory } from 'react-router-dom';
interface Props {
  list: Array<Form>;
  selectedForm?: Form | null;
  handleSelectForm: (id: string) => void | Promise<void>;
}

const FormList = ({ list, selectedForm, handleSelectForm }: Props) => {
  let history = useHistory();

  return (
    <ul className="w-260">
      {list.map((item: Form, index: number) => {
        const isSelected = item.id === selectedForm?.id;

        return (
          <li
            className={`p-10 cursor-pointer w-full${isSelected ? ' bg-gray-40' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              handleSelectForm(item);
              history.push(item.path);
            }}
            key={`form-${index}`}
          >
            {item?.title}
          </li>
        );
      })}
    </ul>
  );
};

export default FormList;
