export const createReseller = `
  mutation createReseller($input: ResellerAdminInput!) {
    createReseller(input: $input) {
      ... on ResellerResponse {
        reseller {
          id
        }
      }

      ... on ErrorResponse {
        __typename
        message
        name
      }
    }
  }
`;
