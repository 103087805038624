import React from 'react';
import '../../styles/button.css';
import Spinner from '../LoadingIndicator/Spinner';

type ButtonProps = {
  handleOnClick?: () => void | Promise<void>;
  innerRef?: () => void | Promise<void>;
  classNames?: string;
  dataCy?: string;
  children?: string | JSX.Element;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
};

const Button = ({
  children,
  handleOnClick,
  classNames,
  dataCy,
  type = 'button',
  innerRef = () => {},
  disabled,
  submitting,
}: ButtonProps) => (
  <button
    onClick={handleOnClick}
    className={`focus:outline-none ${classNames}${disabled ? ' opacity-50 cursor-text' : ''}`}
    data-cy={dataCy}
    type={type}
    ref={innerRef}
    disabled={disabled}
  >
    {submitting ? <Spinner /> : children}
  </button>
);

export default Button;
