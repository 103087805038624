export const generateActivationCodes = `
  query generateActivationCodes($input: GenerateActivationCodesInput!) {
    generateActivationCodes(input: $input) {
        status
        activationCodesGenerated
        s3 {
          filePath
          fileContent
        }
    }
  }
`;
