export const getResellerItem = `
  query getReseller($id: String!){
    getReseller(id: $id) {
      ... on ResellerResponse {
        __typename
        reseller {
          id
          accessId
          name
          isRecurring
          isEmailEditable
          subscriptionInterval
          secret
          priceInCents
          user {
            email
            givenName
            familyName
            addressStreet
            addressStreet2
            addressPostalCode
            addressLocality
            addressRegion
            addressStateCode
            addressCountry
          }
          canRequestJoinEmail
          emailDomain
          ccJoinEmail
        }
      }
    }
  }
`;
