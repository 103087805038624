// @flow
import React, { useState } from 'react';
import Textbox from '../Textbox';
import hidePassword from '../../assets/icons/hide-password.svg';
import showPassword from '../../assets/icons/show-password.svg';

type Props = {
  textbox?: Object;
  iconClassnames?: string;
  classNames?: Object;
};

const PasswordTextbox = ({
  textbox = {
    handleOnChange: () => {},
    handleOnKeyPress: () => {},
    isError: false,
    value: '',
    label: 'Password',
    defaultValue: '',
    inputRef: {},
    error: '',
    dataCy: '',
    id: '',
  },
  classNames = {},
}: Props) => {
  const [type, setType] = useState('password');
  const icon = type === 'password' ? showPassword : hidePassword;

  return (
    <Textbox
      type={type}
      classNames={{
        textbox:
          'w-textbox-base h-textbox-base md:w-textbox-lg md:h-textbox-lg md:text-2lg truncate pl-18',
        label: 'md:text-2lg',
        ...classNames,
      }}
      {...textbox}
      icon={
        <img
          src={icon}
          onClick={() => setType(type === 'password' ? 'text' : 'password')}
          className="icon icon-base absolute md:top-15 right-17 top-10 w-6 bg-no-repeat"
        />
      }
    />
  );
};

export default PasswordTextbox;
