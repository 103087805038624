export const resellerProducts = [
  { name: 'Monthly', value: 1 },
  { name: '2-Monthly', value: 2 },
  { name: '3-Monthly', value: 3 },
  { name: '6-Monthly', value: 6 },
  { name: 'Annual', value: 12 },
];

export const activationCodeProducts = [
  { name: 'activation-code-1m', value: 'activation-code-1m' },
  { name: 'activation-code-2m', value: 'activation-code-2m' },
  { name: 'activation-code-3m', value: 'activation-code-3m' },
  { name: 'activation-code-6m', value: 'activation-code-6m' },
  { name: 'activation-code-12m', value: 'activation-code-12m' },
  { name: 'activation-code-18m', value: 'activation-code-18m' },
  { name: 'lmod-reseller-monthly', value: 'lmod-reseller-monthly' },
];
