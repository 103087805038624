const messages = {
  OVER_DURATION_MAXIMUM:
    'Last enrollment date is 1,000 days far from First enrollment date at maximum',
  OVER_NUMBER_OF_CODES_MAXIMUM: 'Number of codes to generate must be less than or equal to 50,000',
  DUPLICATE_RESELLER_EMAIL:
    'The selected Reseller Administrator has been assigned for another reseller, please enter or select a different one.',
  DUPLICATE_RESELLER_ACCESS_ID: 'Access id must be unique.',
  INVALID_RESELLER_ACCESS_ID:
    'Invalid Access id. It can only contain alphanumeric characters, numbers or any of the following: -/_+=.@!',
};

export default messages;
