import * as React from 'react';
import Notification from '../Notification';

import '../../styles/textbox.css';

type ClassNames = {
  textbox?: string;
  label?: string;
  wrapper?: string;
  innerWrapper?: string;
};

interface TextboxProps {
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void | Promise<void>;
  handleOnFocus?: (e: React.FocusEvent<HTMLInputElement>) => void | Promise<void>;
  handleOnBlur?: (e: React.SyntheticEvent<HTMLInputElement>) => void | Promise<void>;
  handleOnKeyPress?: (e: React.SyntheticEvent<HTMLInputElement>) => void | Promise<void>;
  handleOnPaste?: () => void | Promise<void>;
  value?: string;
  defaultValue?: string;
  inputRef?: {
    current: any;
  };
  label?: string;
  type?: string;
  classNames?: ClassNames;
  id?: string;
  error?: string;
  success?: string;
  dataCy?: string;
  disabled?: boolean;
  placeholder?: string;
  icon?: Node;
}

const Textbox = ({
  defaultValue = '',
  inputRef = {
    current: {},
  },
  value,
  label,
  type = 'text',
  classNames = {
    textbox: '',
    label: '',
    wrapper: '',
    innerWrapper: '',
  },
  handleOnChange,
  handleOnPaste,
  handleOnKeyDown,
  handleOnFocus,
  handleOnBlur,
  error,
  success,
  dataCy = '',
  id = '',
  disabled,
  placeholder,
  icon,
  handleOnKeyPress = () => {},
}: TextboxProps) => (
  <div className={`md:mb-30 mb-26${classNames.wrapper ? ' ' + classNames.wrapper : ''}`}>
    <div className={`relative ${error ? 'error' : ''}${classNames.innerWrapper || ''}`}>
      <input
        className={`${classNames.textbox} textbox${disabled ? ' disabled' : ''}`}
        type={type}
        value={value}
        placeholder={placeholder || label}
        onChange={handleOnChange}
        defaultValue={defaultValue}
        ref={inputRef}
        data-cy={dataCy}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        id={id}
        disabled={disabled}
        key={defaultValue}
        onPaste={handleOnPaste}
        autoComplete="off"
        onKeyDown={handleOnKeyDown}
        onKeyPress={handleOnKeyPress}
      />
      <label className={`textbox-label ${classNames.label}`} htmlFor={id}>
        {label}
      </label>
      {icon}
    </div>
    {error && <Notification type="error" message={error} dataCy={dataCy} />}
    {success && <Notification type="success" message={success} dataCy={dataCy} />}
  </div>
);

export default React.memo<TextboxProps>(Textbox);
