import Amplify, { Auth, graphqlOperation, API } from 'aws-amplify';

export const filterArrayOptions = (options: Array<Option>, selectedOptions: Array<Option>) => {
  let CollectSelected: any = {};

  selectedOptions.forEach((item) => (CollectSelected[item.id] = item));

  return options ? options.filter((item) => !CollectSelected[item.id]) : [];
};

export const cognitoConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_AUTH_FLOW_TYPE,
  },
};

const awsconfig = {
  aws_project_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

const client = () =>
  Amplify.configure({
    ...awsconfig,
    API: {
      graphql_headers: async () => ({
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      }),
      graphql_endpoint_iam_region: process.env.REACT_APP_AWS_COGNITO_REGION,
      graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    },
  });

export const publicAPI = async (
  query: string,
  callback: (res: any) => {},
  handleError = (err: any) => {},
  variables = {},
  handleUnauthorized = () => {}
) => {
  client();

  try {
    const res = await API.graphql(graphqlOperation(query, variables));
    callback(res);
  } catch (err) {
    const error = (err.response && err.response.data && err.response.data.errors) || [];
    if (error[0] && error[0].errorType === 'UnauthorizedException') {
      handleUnauthorized();
    }

    // There are 2 cases for this catching:
    // - Error during executing any internal logic (there is no errors array there)
    // - Error throws from api (includes errors)
    handleError(error.errors && error.errors.length > 0 ? error.errors[0] : err);
  }
};

export const debounce = (callback: any, waitFor: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<any>): ReturnType<any> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};

export const clearLocalStorage = () => {
  window.localStorage && window.localStorage.clear();
};

export const checkIsAdmin = (user: { idToken: { payload: { 'cognito:groups': string[] } } }) => {
  const payload = user.idToken && user.idToken.payload;
  const cognitoGroups = payload['cognito:groups'];
  return cognitoGroups.includes('LMOD_Support_Users');
};

export const convertCentsToEuro = (cents = 0) => {
  return (cents / 100).toFixed(2);
};

export const isFloatWith2Decimal = (num: string) => {
  const pattern = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;

  return pattern.test(num);
};
