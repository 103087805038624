import Textbox from '../Textbox';
import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import Radio from '../Radio';
import MultipleSelect from '../MultipleSelect';
import { publicAPI } from '../../utils/utilities';
import { getCountryCodes } from '../../graphql/getCountryCodes';
import { GlobalContext } from '../../pages/Dashboard';

import { isFloatWith2Decimal } from '../../utils/utilities';
import Autocomplete from '../Autocomplete';
import { debounce } from '../../utils/utilities';
import Button from '../Button';
import { FormValidator, PATTERNS } from '../../utils/validator';
import { resellerProducts } from '../../constants/forms';
import { getUserByEmail } from '../../graphql/getUserByEmail';

import messages from '../../constants/messages';
import { queryReseller } from '../../graphql/queryReseller';
import { createReseller } from '../../graphql/createResellerAdmin';
import LoadingIndicator from '../LoadingIndicator';
import Alert from '../Alert';

const AddReseller = () => {
  const genSecret = useMemo(() => crypto.randomUUID(), []);
  const [setUnauthorized] = useContext(GlobalContext);
  const [isExpandEmail, setIsExpandEmail] = useState(false);
  const [existingUsers, setExistingUsers] = useState([]);
  const [keySearchEmail, setKeySearchEmail] = useState('');
  const [apiResponse, setApiResponse] = useState({});

  const [allCountries, setAllCountries] = useState([]);
  const [isProcessing, setIsProcessing] = useState({ country: true });

  const [reseller, setReseller] = useState({
    accessId: '',
    name: '',
    priceInCents: 0,
    isRecurring: true,
    secret: genSecret,
    isEmailEditable: true,
    product: {},
    canRequestJoinEmail: false,
    emailDomain: [],
    ccJoinEmail: '',
  });
  const [errors, setErrors] = useState({});

  const [user, setUser] = useState({
    email: '',
    firstName: '',
    lastName: 'Reseller',
    addressStreet: '',
    addressStreet2: '',
    addressPostalCode: '',
    addressLocality: '',
    addressStateCode: '',
    addressCountry: '',
    isReseller: false,
  });

  useEffect(() => {
    publicAPI(
      getCountryCodes,
      ({ data }) => {
        data = data.selectionItemListPromotion.items;
        setAllCountries(data);
        setIsProcessing({
          ...isProcessing,
          country: false,
        });
      },
      undefined,
      { form: { current: { name: 'country_code', items: { value: '' } } } },
      () => setUnauthorized(true)
    );
  }, []);

  const onChangeTextboxReseller = (e, fieldName) => {
    const value = e.target.value;

    setReseller({ ...reseller, [fieldName]: e.target.value });

    if (fieldName === 'name') {
      setUser({ ...user, firstName: value });
    }
  };

  const onChangeTextboxUser = (e, fieldName) => {
    setUser({ ...user, [fieldName]: e.target.value });
  };

  const renderListItem = (item) => {
    return <p className="text-base font-base-light">{item.name || item.email}</p>;
  };

  const handleOnChangePrice = (e) => {
    const num = e.target && e.target.value.trim();
    if (num && !isFloatWith2Decimal(num)) return false;

    setReseller({
      ...reseller,
      priceInCents: num,
    });
  };

  const handleSearchUser = (key) => {
    publicAPI(
      getUserByEmail,
      ({ data }) => {
        data = data.getUserByEmail && data.getUserByEmail.user;
        setExistingUsers(data ? [{ ...data }] : []);
      },
      undefined,
      { email: key },
      () => setUnauthorized(true)
    );
  };

  const debouncedSearch = useCallback(
    debounce((newValue, request) => request(newValue), 500),
    []
  );

  const handleOutFocus = () => {
    setReseller({
      ...reseller,
      priceInCents: reseller.priceInCents ? Number(reseller.priceInCents).toFixed(2) : 0,
    });
  };

  const handleErrors = () => {
    const { accessId = '', name = '', product, secret = '', emailDomain = [] } = reseller;
    const { addressCountry = '', email = '' } = user;
    const errs = {
      accessId: FormValidator(['REQUIRED'], accessId.trim(), 'Access id') || errors.accessId,
      name: FormValidator(['REQUIRED'], name, 'Name'),
      product: FormValidator(['REQUIRED'], product.value, 'Default product'),
      addressCountry: FormValidator(['REQUIRED'], addressCountry, 'Country'),
      email: FormValidator(['REQUIRED', 'EMAIL'], email, 'Email', 'Please enter a valid email'),
      secret: FormValidator(['REQUIRED'], secret, 'Secret key'),
    };

    if (reseller.canRequestJoinEmail) {
      errs.emailDomain = FormValidator(['REQUIRED'], emailDomain, 'Email domain(s)');

      if (!errs.emailDomain && emailDomain.includes('@')) {
        errs.emailDomain = "'@' is not allowed in this field";
      }
    }

    setErrors(errs);
    console.error('errs: ', errs);

    return errs;
  };

  const handleSelectEmail = (item) => {
    const { email, isReseller } = item;
    setKeySearchEmail(email);
    setIsExpandEmail(false);

    if (isReseller) {
      setErrors({
        ...errors,
        email: messages.DUPLICATE_RESELLER_EMAIL,
      });

      setUser({
        email: '',
        firstName: '',
        lastName: 'Reseller',
        addressStreet: '',
        addressStreet2: '',
        addressPostalCode: '',
        addressLocality: '',
        addressStateCode: '',
        addressCountry: '',
        isReseller: true,
      });

      return;
    }
    setUser({
      ...item,
      addressCountry: item.addressCountry.toUpperCase(),
    });
  };

  const handleResetForm = () => {
    setErrors({});
    setReseller({
      accessId: '',
      name: '',
      product: {
        value: '',
        name: '',
      },
      priceInCents: 0,
      isRecurring: true,
      secret: genSecret,
      isEmailEditable: true,
      canRequestJoinEmail: false,
      emailDomain: [],
      ccJoinEmail: '',
    });

    setUser({
      email: '',
      firstName: '',
      lastName: 'Reseller',
      addressStreet: '',
      addressStreet2: '',
      addressPostalCode: '',
      addressLocality: '',
      addressStateCode: '',
      addressCountry: '',
      isReseller: false,
    });

    setKeySearchEmail('');
    setExistingUsers([]);
  };

  const onOutFocusAccessId = (e) => {
    const key = e.target.value && e.target.value.trim();

    if (key) {
      if (!PATTERNS.ACCESS_ID.test(key)) {
        setErrors({
          ...errors,
          accessId: messages.INVALID_RESELLER_ACCESS_ID,
        });

        return;
      }

      publicAPI(
        queryReseller,
        ({ data }) => {
          data = data.queryReseller && data.queryReseller.data;
          if (data.length) {
            setErrors({
              ...errors,
              accessId: messages.DUPLICATE_RESELLER_ACCESS_ID,
            });
          }

          return;
        },
        undefined,
        { accessId: key },
        () => setUnauthorized(true)
      );
    }
  };

  const handleSubmit = () => {
    setIsProcessing({
      ...isProcessing,
      createReseller: true,
    });
    const {
      accessId,
      name,
      product,
      priceInCents,
      secret,
      isEmailEditable,
      isRecurring,
      canRequestJoinEmail,
      emailDomain,
      ccJoinEmail,
    } = reseller;
    const {
      email,
      addressStreet,
      addressStreet2,
      addressPostalCode,
      addressLocality,
      addressStateCode,
      addressCountry,
    } = user;

    const input = {
      accessId,
      name,
      priceInCents: parseInt(priceInCents * 100),
      isRecurring,
      secret,
      isEmailEditable,
      subscriptionInterval: product?.value,
      email,
      firstName: name,
      lastName: 'Reseller',
      addressStreet: addressStreet || '',
      addressStreet2: addressStreet2 || '',
      addressPostalCode: addressPostalCode || '',
      addressLocality: addressLocality || '',
      addressStateCode: addressStateCode || '',
      addressCountry,
      canRequestJoinEmail,
    };

    if (canRequestJoinEmail) {
      // convert string of emailDomain1;emailDomain2 by array split with ";"
      const emailDomainList = [];
      emailDomain.split(';').forEach((element) => {
        if (element) {
          emailDomainList.push(element.trim().replace('@', ''));
        }
      });

      input.emailDomain = emailDomainList;
      input.ccJoinEmail = ccJoinEmail;
    }

    publicAPI(
      createReseller,
      ({ data }) => {
        setIsProcessing({
          ...isProcessing,
          createReseller: false,
        });
        data = data.createReseller;

        if (data.__typename === 'ErrorResponse') {
          setApiResponse({
            message: data.message,
            type: 'error',
          });

          return;
        }

        if (data) {
          setApiResponse({
            type: 'success',
            message: 'Create reseller successfully',
          });
        }
      },
      undefined,
      { input },
      () => setUnauthorized(true)
    );
  };

  const disabledUserInfo = existingUsers.length && !user.isReseller;

  return (
    <>
      {isProcessing.createReseller && <LoadingIndicator />}
      <Alert
        message={apiResponse.message}
        showAlert={!!apiResponse.message}
        onHideAlert={() => setApiResponse({})}
        type={apiResponse.type}
        showCloseIcon={apiResponse.type === 'error'}
      />
      <p className="font-base-black text-gray-800 leading-tight mb-35 mt-35">Reseller details</p>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Access id <span className="text-error">*</span>:
        </p>
        <Textbox
          value={reseller.accessId}
          handleOnChange={(e) => {
            setErrors({
              ...errors,
              accessId: '',
            });
            onChangeTextboxReseller(e, 'accessId');
          }}
          classNames={{
            wrapper: 'w-textbox-lg mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          handleOnBlur={onOutFocusAccessId}
          error={errors.accessId}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Name <span className="text-error">*</span>:
        </p>
        <Textbox
          value={reseller.name}
          handleOnChange={(e) => onChangeTextboxReseller(e, 'name')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.name}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Default product <span className="text-error">*</span>:
        </p>
        <MultipleSelect
          options={resellerProducts}
          setValue={(value) => {
            setReseller({ ...reseller, product: value });
            setErrors({
              ...errors,
              product: '',
            });
          }}
          renderListItem={renderListItem}
          error={errors.product}
          value={reseller.product.name}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Accumulated price <span className="text-error">*</span>:
        </p>
        <Textbox
          value={reseller.priceInCents}
          handleOnChange={(e) => handleOnChangePrice(e)}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.priceInCents}
          handleOnBlur={handleOutFocus}
        />
      </div>
      <div className="flex items-baseline mb-32 justify-between">
        <p className="font-base-light text-gray-800 leading-tight">
          Recurring <span className="text-error">*</span>:
        </p>
        <div className="flex items-baseline w-textbox-lg">
          <Radio
            name="isRecurring"
            value={true}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                isRecurring: true,
              });
            }}
            checked={reseller.isRecurring === true}
            label={<p>recurring</p>}
            classNames={{
              wrapper: 'radio-wrapper font-base-light text-gray-800 leading-tight text-base mr-30',
              checkmark: 'radio-checkmark',
            }}
          />
          <Radio
            name="isRecurring"
            value={false}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                isRecurring: false,
              });
            }}
            checked={reseller.isRecurring === false}
            label={<p>fixed item</p>}
            classNames={{
              wrapper:
                'radio-wrapper font-base-light text-gray-800 leading-tight text-base text-left',
              checkmark: 'radio-checkmark',
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Secret <span className="text-error">*</span>:
        </p>
        <Textbox
          value={reseller.secret}
          handleOnChange={(e) => onChangeTextboxReseller(e, 'secret')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.secret}
        />
      </div>
      <div className="flex items-baseline mb-32 justify-between">
        <p className="font-base-light text-gray-800 leading-tight">
          Is email editable? <span className="text-error">*</span>:
        </p>
        <div className="flex items-baseline w-textbox-lg">
          <Radio
            name="isEmailEditable"
            value={true}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                isEmailEditable: 1,
              });
            }}
            checked={reseller.isEmailEditable === true}
            label={<p>editable</p>}
            classNames={{
              wrapper: 'radio-wrapper font-base-light text-gray-800 leading-tight text-base mr-30',
              checkmark: 'radio-checkmark',
            }}
          />
          <Radio
            name="isEmailEditable"
            value={false}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                isEmailEditable: false,
              });
            }}
            checked={reseller.isEmailEditable === false}
            label={<p>not editable</p>}
            classNames={{
              wrapper:
                'radio-wrapper font-base-light text-gray-800 leading-tight text-base text-left',
              checkmark: 'radio-checkmark',
            }}
          />
        </div>
      </div>
      <div className="flex items-baseline mb-32 justify-between">
        <p className="font-base-light text-gray-800 leading-tight">
          Can request join email? <span className="text-error">*</span>:
        </p>
        <div className="flex items-baseline w-textbox-lg">
          <Radio
            name="canRequestJoinEmail"
            value={true}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                canRequestJoinEmail: true,
              });
            }}
            checked={reseller.canRequestJoinEmail === true}
            label={<p>Yes</p>}
            classNames={{
              wrapper: 'radio-wrapper font-base-light text-gray-800 leading-tight text-base mr-30',
              checkmark: 'radio-checkmark',
            }}
          />
          <Radio
            name="canRequestJoinEmail"
            value={false}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                canRequestJoinEmail: false,
              });
            }}
            checked={reseller.canRequestJoinEmail === false}
            label={<p>No</p>}
            classNames={{
              wrapper:
                'radio-wrapper font-base-light text-gray-800 leading-tight text-base text-left',
              checkmark: 'radio-checkmark',
            }}
          />
        </div>
      </div>
      {reseller.canRequestJoinEmail && (
        <>
          <div className="flex justify-between items-baseline">
            <p className="font-base-light text-gray-800 leading-tight">
              Email domain(s) <span className="text-error">*</span>:
            </p>
            <Textbox
              value={reseller.emailDomain}
              handleOnChange={(e) => onChangeTextboxReseller(e, 'emailDomain')}
              classNames={{
                wrapper: 'textbox-wrapper mb-32',
                textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
                label: 'text-2lg',
              }}
              error={errors.emailDomain}
            />
          </div>

          <div className="flex justify-between items-baseline">
            <p className="font-base-light text-gray-800 leading-tight">CC join Email:</p>
            <Textbox
              value={reseller.ccJoinEmail}
              handleOnChange={(e) => onChangeTextboxReseller(e, 'ccJoinEmail')}
              classNames={{
                wrapper: 'textbox-wrapper mb-32',
                textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
                label: 'text-2lg',
              }}
              error={errors.ccJoinEmail}
            />
          </div>
        </>
      )}
      <p className="font-base-black text-gray-800 leading-tight mt-50 mb-35">
        Reseller Administrator
      </p>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Email <span className="text-error">*</span>:
        </p>
        <Autocomplete
          error={errors.email}
          toggleDropdown={setIsExpandEmail}
          isExpanded={isExpandEmail}
          onChange={(e) => {
            const keyEvent = e.target.value;

            setKeySearchEmail(keyEvent);
            setUser({
              ...user,
              email: keyEvent,
            });
            setErrors({
              ...errors,
              email: '',
            });

            debouncedSearch(keyEvent, handleSearchUser);
          }}
          onSelect={handleSelectEmail}
          results={existingUsers}
          keySearch={keySearchEmail}
          // setErrors={handleErrors}
          // selectedOption={currentForm}
          handleOnFocus={() => setIsExpandEmail(true)}
          renderListItem={renderListItem}
          handleOnBlur={() => {}}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          First name <span className="text-error">*</span>:
        </p>
        <Textbox
          value={user.firstName}
          handleOnChange={(e) => onChangeTextboxUser(e, 'firstName')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.firstName}
          disabled={true}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Last name <span className="text-error">*</span>:
        </p>
        <Textbox
          value={user.lastName}
          handleOnChange={(e) => onChangeTextboxUser(e, 'lastName')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.lastName}
          disabled={true}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address street:</p>
        <Textbox
          value={user.addressStreet}
          handleOnChange={(e) => onChangeTextboxUser(e, 'addressStreet')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled={disabledUserInfo}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address street 2:</p>
        <Textbox
          value={user.addressStreet2}
          handleOnChange={(e) => onChangeTextboxUser(e, 'addressStreet2')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled={disabledUserInfo}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address postal code:</p>
        <Textbox
          value={user.addressPostalCode}
          handleOnChange={(e) => onChangeTextboxUser(e, 'addressPostalCode')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled={disabledUserInfo}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address city:</p>
        <Textbox
          value={user.addressLocality}
          handleOnChange={(e) => onChangeTextboxUser(e, 'addressLocality')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled={disabledUserInfo}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address state code:</p>
        <Textbox
          value={user.addressStateCode}
          handleOnChange={(e) => onChangeTextboxUser(e, 'addressStateCode')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled={disabledUserInfo}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Address country <span className="text-error">*</span>:
        </p>
        <MultipleSelect
          options={allCountries.map((item) => ({
            name: item.value,
            id: item.value,
          }))}
          setValue={({ name }) => {
            setUser({
              ...user,
              addressCountry: name,
            });
          }}
          renderListItem={renderListItem}
          error={errors.addressCountry}
          value={user.addressCountry}
          loading={isProcessing.country}
          disabled={disabledUserInfo}
        />
      </div>
      <div className="flex justify-end">
        <Button
          classNames=" btn btn-primary w-btn-xs mb-30 mr-30 mt-30"
          type="submit"
          handleOnClick={() => {
            const error = handleErrors();
            if (Object.keys(error).some((key) => error[key])) {
              return;
            }
            handleSubmit();
          }}
          disabled={isProcessing.createReseller}
        >
          Save
        </Button>
        <Button
          classNames=" btn btn-primary w-btn-xs mb-30 mt-30"
          type="submit"
          handleOnClick={() => {
            handleResetForm();
          }}
          disabled={isProcessing.createReseller}
        >
          Cancel
        </Button>
      </div>
      <div>
        <p className="font-base-black italic">Notes:</p>
        <u className="no-underline list-disc list-inside font-base-light">
          <li>For multiple Email Domain entries, separate values by ';' in text string</li>
        </u>
      </div>
    </>
  );
};

export default AddReseller;
