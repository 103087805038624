export const handleClickOutSideRecaptcha = (onClickOutsideRecaptcha = () => {}) => {
  // Find the open reCaptcha iframe
  HTMLCollection.prototype.find = Array.prototype.find;
  const recaptchaIframe = document
    .getElementsByTagName('iframe')
    .find(
      (elementIFrame) =>
        elementIFrame.src && elementIFrame.src.includes('google.com/recaptcha/api2/bframe')
    );
  if (recaptchaIframe) {
    // Find the reCaptcha window
    let recaptchaWindow = recaptchaIframe.parentNode.parentNode;

    // Listen event closed iframe by click outside reCaptcha
    new MutationObserver(
      () =>
        recaptchaWindow.style.visibility === 'hidden' &&
        !window.grecaptcha.getResponse() &&
        onClickOutsideRecaptcha()
    ).observe(recaptchaWindow, { attributes: true, attributeFilter: ['style'] });
  }
};

export const ERRORS = {
  NotAuthorizedException: 'Your email and password do not match. Please try again',
  FailedRecaptchaV3: 'failed recaptcha v3 verification',
  IncorrectEmail: 'Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed.',
  IncorrectPassword: 'Password must contain more than 6 characters, please try again.',
  NoPermission:
    'Sorry, you have no permission to access this site. Please contact Support Team for any concerns.',
};
