export const searchResellerByName = `
  query searchResellerByName($name: String, $lastEvaluatedKey: String){
    searchResellerByName(name: $name, lastEvaluatedKey: $lastEvaluatedKey) {
      ... on ResellerAdminList {
        __typename
        lastEvaluatedKey
        data {
          id
          accessId
          name
        }
      }
    }
  }
`;
