import React from 'react';

interface SpinnerProps {
  className?: string;
}

const Spinner = ({ className = '' }: SpinnerProps) => (
  <div
    className={`w-full h-full absolute flex top-0 left-0 z-50 justify-center items-center spinner-wrapper ${className}`}
  >
    <div
      className={`loader-animation ease-linear rounded-full border-gray-75 h-8 w-8 border-4 border-t-4 default-loader`}
    ></div>
  </div>
);

export default Spinner;
