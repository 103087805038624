import * as React from 'react';

type classNames = {
  wrapper?: string;
};

interface Props {
  type: 'error' | 'success';
  message: string;
  dataCy?: string;
  classNames?: classNames;
}

const Notification = ({ type, message, dataCy = '', classNames = {} }: Props) => {
  return (
    <div className={`${classNames.wrapper ? `flex ${classNames.wrapper}` : 'flex mt-2'}`}>
      <div className="flex text-xs md:text-base items-center text-left font-base-light leading-tight">
        <p className={`mt-3 text-${type}`} data-cy={`${dataCy}-${type}`}>
          {message}
        </p>
      </div>
    </div>
  );
};

export default React.memo<Props>(Notification);
